<template>
  <ion-row class="thumb-article-row">
    <ion-col size="auto" class="thumbnail-column">
      <ion-thumbnail class="thumbnail">
        <img :src="image" :alt="title">
      </ion-thumbnail>
    </ion-col>

    <ion-col class="content-column">
      <div class="content-rows">
        <ion-row>
          <ion-col style="padding-left: 0; padding-top: 0; margin-top: -5px">
            <ion-button class="button-general danger">
              {{ category.name }}
            </ion-button>
          </ion-col>
          <ion-col class="heart-icon" v-if="displayFavoriteIcon">
            <HeartIcon :include-bg="false" :marked="true"/>
          </ion-col>
        </ion-row>

        <ion-row class="outline-info">
          <TitleWithInfo :title="title"
                         :read-time="readTime" :viewed="viewed"/>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo.vue";
import {IonButton, IonCol, IonRow, IonThumbnail} from '@ionic/vue';
import HeartIcon from "@/components/icons/HeartIcon.vue";

export default {
  name: "ArticleXsThumbnailCard",
  components: {
    TitleWithInfo,
    IonThumbnail,
    IonButton,
    IonRow,
    IonCol,
    HeartIcon
  },
  props: {
    displayFavoriteIcon: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    readTime: {
      type: String,
      required: true
    },
    viewed: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
