
import Header from "@/layouts/Header.vue";
import ArticleXsThumbnailCard from "@/components/cards/ArticleXsThumbnailCard.vue"
import {IonContent, IonGrid, IonPage, IonRow, IonSearchbar} from '@ionic/vue';
import FilterIcon from '@/components/icons/FilterIcon.vue';
import store from '@/store';
import {ThumbnailArticleInterface} from "@/interfaces/Article";
import {computed, ref} from "vue";

export default {
  name: 'search',
  components: {
    ArticleXsThumbnailCard,
    Header,
    IonContent,
    IonPage,
    IonSearchbar,
    FilterIcon,
    IonRow,
    IonGrid,
  },
  setup: () => {
    const displayFilter = ref(true);
    const string = ref('');
    const articles: Array<ThumbnailArticleInterface> = store.state.articles;
    const filteredArticles = computed(() => articles.filter(article => string.value && article.title.toLowerCase().indexOf(string.value.toLowerCase()) > -1))
    const onSearch = (event) => {
      string.value = event.target.value
    }
    const toggleDisplayFilter = () => {
      displayFilter.value = !displayFilter.value;
    }
    return {
      filteredArticles,
      string,
      displayFilter,
      onSearch,
      toggleDisplayFilter
    }
  }
}
