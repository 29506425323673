
import TitleWithInfo from "@/components/cards/partials/TitleWithInfo.vue";
import {IonButton, IonCol, IonRow, IonThumbnail} from '@ionic/vue';
import HeartIcon from "@/components/icons/HeartIcon.vue";

export default {
  name: "ArticleXsThumbnailCard",
  components: {
    TitleWithInfo,
    IonThumbnail,
    IonButton,
    IonRow,
    IonCol,
    HeartIcon
  },
  props: {
    displayFavoriteIcon: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    readTime: {
      type: String,
      required: true
    },
    viewed: {
      type: Number,
      required: true
    }
  },
}
