<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8 9C8 10.8638 6.72523 12.4299 5 12.874L5 20L3 20L3 12.874C1.27477 12.4299 -3.99354e-07 10.8638 -4.80825e-07 9C-5.7739e-07 6.79086 1.79086 5 4 5C6.20914 5 8 6.79086 8 9ZM5 3L3 3L3 -1.31134e-07L5 -2.18557e-07L5 3ZM18 14C18 15.8638 16.7252 17.4299 15 17.874L15 20L13 20L13 17.874C11.2748 17.4299 10 15.8638 10 14C10 11.7909 11.7909 10 14 10C16.2091 10 18 11.7909 18 14ZM15 8L15 -6.55671e-07L13 -5.68248e-07L13 8L15 8ZM14 12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12ZM4 7C5.10457 7 6 7.89543 6 9C6 10.1046 5.10457 11 4 11C2.89543 11 2 10.1046 2 9C2 7.89543 2.89543 7 4 7Z"
          fill="#333333"/>
  </svg>
</template>
<script>
export default {
  name: "FilterIcon"
}
</script>

<style scoped>

</style>
