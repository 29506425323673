<template>
  <ion-page>
    <Header title="Search"/>
    <ion-content :fullscreen="true">
      <ion-grid class="search-section">
        <IonSearchbar v-model="string"
                      @input="onSearch"
                      @ionFocus="toggleDisplayFilter"
                      @ionBlur="toggleDisplayFilter"
        />

        <ion-row class="filter-icon-area" v-show="displayFilter">
          <FilterIcon/>
        </ion-row>
      </ion-grid>

      <ion-grid class="thumb-article-section" :style="{paddingTop: displayFilter ? 0 : '20px'}">
        <ArticleXsThumbnailCard v-for="(article, index) in filteredArticles"
                                :key="index"
                                :title="article.title"
                                :image="article.image"
                                :category="article.category"
                                :read-time="article.readTime"
                                :viewed="article.viewed"
        />
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import Header from "@/layouts/Header.vue";
import ArticleXsThumbnailCard from "@/components/cards/ArticleXsThumbnailCard.vue"
import {IonContent, IonGrid, IonPage, IonRow, IonSearchbar} from '@ionic/vue';
import FilterIcon from '@/components/icons/FilterIcon.vue';
import store from '@/store';
import {ThumbnailArticleInterface} from "@/interfaces/Article";
import {computed, ref} from "vue";

export default {
  name: 'search',
  components: {
    ArticleXsThumbnailCard,
    Header,
    IonContent,
    IonPage,
    IonSearchbar,
    FilterIcon,
    IonRow,
    IonGrid,
  },
  setup: () => {
    const displayFilter = ref(true);
    const string = ref('');
    const articles: Array<ThumbnailArticleInterface> = store.state.articles;
    const filteredArticles = computed(() => articles.filter(article => string.value && article.title.toLowerCase().indexOf(string.value.toLowerCase()) > -1))
    const onSearch = (event) => {
      string.value = event.target.value
    }
    const toggleDisplayFilter = () => {
      displayFilter.value = !displayFilter.value;
    }
    return {
      filteredArticles,
      string,
      displayFilter,
      onSearch,
      toggleDisplayFilter
    }
  }
}
</script>
